import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const Head = () => <Seo title="404 : Page non trouvée" />

const NotFoundPage = () => (
  <Layout content="page">
    <h1>Page non trouvée</h1>
    <p>
      <Link to="/" title="My tour of Europe">
        Revenir à l’accueil
      </Link>
    </p>
  </Layout>
)

export default NotFoundPage
